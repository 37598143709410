import { Typography } from "@suraasa/placebo-ui"
import { SubmissionPlagiarismReport } from "api/resources/submissions/types"
import { useEffect, useRef, useState } from "react"

import PlagiarismSourceSheet from "./PlagiarismSourceSheet"

type Props = {
  data: SubmissionPlagiarismReport
  submissionId: string
}

const PlagiarisedSubmissionView = ({ data, submissionId }: Props) => {
  const [showSourceSheet, setShowSourceSheet] = useState({
    show: false,
    sourceId: "",
  })

  const ref = useRef<HTMLParagraphElement>(null)

  const { updatedHtml, plagiarisedSourcesUsers } = data

  const showPlagiarismSource = (e: Event) => {
    const element = e.target as Element
    if (element) {
      const id = element.getAttribute("data-content-id")
      if (id) {
        setShowSourceSheet({
          show: true,
          sourceId: id,
        })
      } else {
        setShowSourceSheet({
          show: false,
          sourceId: "",
        })
      }
    }
  }

  useEffect(() => {
    if (ref.current) {
      const elements = document.querySelectorAll(".suraasa-plagiarism-detected")
      elements.forEach(el => el.addEventListener("click", showPlagiarismSource))
      elements.forEach(el => {
        const chunkId = el.getAttribute("data-content-id")
        const sourceId = el.getAttribute("source-uuid")

        if (!sourceId) {
          console.warn(`Source ID not found for chunk: ${chunkId}`)
          return
        }

        const groupId =
          plagiarisedSourcesUsers.findIndex(
            source => source.sourceSubmissionUuid === sourceId
          ) + 1

        if (groupId <= 0) {
          console.warn(`Group ID not found for ${sourceId}`)
        }
        groupId && el.setAttribute("data-before", `${groupId}`)
      })
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, data])

  return (
    <>
      <PlagiarismSourceSheet
        open={showSourceSheet.show}
        onClose={() =>
          setShowSourceSheet({
            show: false,
            sourceId: "",
          })
        }
        sourceId={showSourceSheet.sourceId}
        submissionId={submissionId}
      />
      <div className="show-plagiarism-highlights">
        <Typography
          ref={ref}
          variant="body"
          className="user-submission-text"
          dangerouslySetInnerHTML={{
            __html: updatedHtml,
          }}
        />
      </div>
    </>
  )
}

export default PlagiarisedSubmissionView
