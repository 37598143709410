import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getCelestialURL = getServiceURL(ServicePrefix.celestial)

export const urls = validateUrls({
  submissions: {
    retrieve: submissionId =>
      getCelestialURL(`/v1/assignments/submissions/${submissionId}`),

    listAssignmentUserSubmissions: (assignmentId, userId) =>
      getCelestialURL(
        `/v1/assignments/${assignmentId}/users/${userId}/submissions/`
      ),
  },

  users: {
    assessors: () => `/celestial/v1/centres/assessors/`,
  },

  plagiarism: {
    getPlagiarisedSubmission: submissionId =>
      `/v1/submissions/${submissionId}/plagiarism/`,
    getPlagiarismSources: (submissionId, sourceId) =>
      `/v1/submissions/${submissionId}/plagiarism-sources/${sourceId}/`,
  },
})
